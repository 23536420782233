import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, IconButton } from '@mui/material';
import Edit02Icon from '@untitled-ui/icons-react/build/esm/Edit02';
import VisibilityIcon from '@mui/icons-material/Visibility';
const Textarea = ({ field, value, onChange }) => {
    const [formValues, setFormValues] = useState({});
    const dispatch = useDispatch();
    const fieldRef = useRef(null);
    const pageForm = localStorage.getItem('formType');

    const handleInputChange = (e) => {
        onChange(field.fieldName, e.target.value);
    };




    return (
        <TextField
            multiline
            id={field.fieldName}
            name={field.fieldName}
            onChange={handleInputChange}
            rows={15}
            maxRows={40}
            fullWidth
            defaultValue={value}
            ref={fieldRef}
        />
    );
};

export default Textarea;
